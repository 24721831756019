import { filter, first } from 'rxjs';

import { CommonModule, Location } from '@angular/common';
import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, Input
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { NavigationEnd, Router } from '@angular/router';
import { CalendarDatePipe } from '@app/pipes/calendar-date.pipe';
import { TranslitPipe } from '@app/pipes/translit.pipe';
import { ThemeService } from '@app/services/theme.service';
import { Tag, TagCoordinates, TagGroup, TagsService } from '@services/tags.service';
import { UtilsService } from '@services/utils.service';

@Component({
  selector: 'app-serbian-map',
  templateUrl: './serbian-map.component.html',
  styleUrls: ['./serbian-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatMenuModule, MatIconModule,
    MatButtonModule, CalendarDatePipe, TranslitPipe,]
})
export class SerbianMapComponent {
  @Input() citySlug!: string | null;
  @Input() smallMap: boolean = false;
  @Input() biggerMap: boolean = false;

  private tagsService = inject(TagsService);
  private location = inject(Location);
  private cdr = inject(ChangeDetectorRef);
  private router = inject(Router);
  private destroyRef = inject(DestroyRef);

  public zoomLevel = 1;
  public selectedZoom: boolean = false;
  public selectedText: boolean = false;
  public singleSize = '';
  public cities: TagCoordinates[] = [];
  public themeService = inject(ThemeService);
  public lastCity: string | null = null;
  public isMobileDevice: boolean = false;

  ngOnInit() {
    this.checkMobileDevice();
    this.getItems();
    this.locationEventListen();
  }

  locationEventListen() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(_ => {
        this.onZoomOut();
      });
  }

  checkMobileDevice() {
    this.isMobileDevice = UtilsService.checkMobileDevice();
  }

  getItems() {
    this.tagsService.getItems()
      .pipe(first())
      .subscribe({
        next: (response: Tag[]) => {
          const cityTags = response.filter(t => t.group === TagGroup.cities);
          this.cities = this.tagsService
            .setCityCoordinates(cityTags, this.citySlug);

          if (this.citySlug) {
            this.singleSize = 'size-2';
          }

          setTimeout(() => {
            this.cdr.detectChanges();
          }, 1);
        }
      });
  }

  goToCityData(slug: string | null) {
    this.router.navigateByUrl(`tag/${slug}/podaci`);
  }

  goToCityPersons(slug: string | null) {
    this.router.navigateByUrl(`tag/${slug}/licnosti`);
  }

  goToCitySights(slug: string | null) {
    this.router.navigateByUrl(`tag/${slug}/znamenitosti`);
  }

  onCityLogo(slug: string | null) {
    this.goToCityData(slug);
  }

  onCityPersons(slug: string | null) {
    const url = `tag/${slug}/licnosti`;
    this.router.navigateByUrl(url);
  }

  goToCityDataExternal(slug: string | null) {
    const url = `tag/${slug}/podaci`;
    UtilsService.openOnMiddleClick(url);
  }

  goToCityPersonsExternal(slug: string | null) {
    const url = `tag/${slug}/licnosti`;
    UtilsService.openOnMiddleClick(url);
  }

  goToCitySightsExternal(slug: string | null) {
    const url = `tag/${slug}/znamenitosti`;
    UtilsService.openOnMiddleClick(url);
  }

  goToTag(path: string) {
    const url = `tag/${path}`;
    this.router.navigateByUrl(url);
  }

  onLastCity(city: TagCoordinates) {
    this.lastCity = city.name;

    setTimeout(() => {
      this.lastCity = null;
      this.cdr.detectChanges();
    }, 500);
  }

  onDot(city: TagCoordinates) {
    if (this.singleSize) {
      return;

    }
    if (this.zoomLevel === 1 && this.isMobileDevice) {
      this.onZoomIn(city);
    } else if (this.zoomLevel === 2 && this.isMobileDevice) {
      this.onCityPersons(city.slug);
    }
  }

  onZoomIn(city?: TagCoordinates) {
    this.zoomLevel = 2;
    const href = this.router.url;
    this.location.go(href + '#');
    this.selectedText = true;
    const element = document.querySelector('.serbian-map-container');

    const cityLeft = city?.left || '0';
    const cityTop = city?.top || '0';
    const x = +cityLeft.slice(0, -1) * 11 - 20;
    const y = +cityTop.slice(0, -1) * 18 - 40;
    const left = Math.floor(x);
    const top = Math.floor(y);
    const behavior = 'smooth';

    setTimeout(() => {
      element!.scrollTo({ left, top, behavior });
    }, 1);
  }

  onZoomOut() {
    this.selectedText = false;
    this.zoomLevel = 1;
    this.cdr.detectChanges();
  }

};